/**
 *		Imports only base, component, and layout styles necessary to render the
 *		"critical" css (above-the-fold content)
 */
@import 'base/resets';
@import 'base/functions';
@import 'base/variables';
@import 'base/placeholders';
@import 'base/mixins';
@import 'base/base';

// Add basic changes for data-font-size/data-contrast-mode and accessibility toolbar
// @import 'components/accessibility';

@import 'components/sitewide-alerts';
@import 'components/aspect-ratios';
//@import 'components/bloqs';
@import 'components/buttons';

// Header/Navigation with basic main content layout and sidebar
@import 'layout/header';
@import 'layout/content';
@import 'layout/aside';