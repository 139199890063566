figure {
	margin: 0;

	&.aspect {
		position: relative;
		width: 100%;
		height: 0;
		overflow: hidden;
		margin: 0;

		&.portrait		{ padding-bottom: 160%; }
		&.square		{ padding-bottom: 100%; }
		&.traditional	{ padding-bottom: 75%; }
		&.computer		{ padding-bottom: 62.5%; }
		&.hd,
		&.video,
		&.landscape		{ padding-bottom: 56.25%; }
		&.widescreen	{ padding-bottom: 54%; }
		&.cinema		{ padding-bottom: 42%; }
		&.banner		{ padding-bottom: 25%; }

		> svg {
			@extend %center;
			width: 100%;
			height: 100%;
		}

		> img {
			width: 100%;
		}

		@supports ((object-fit: cover) or (object-fit: contain)) {
			> svg {
				display: none;
			}

			> img {
				object-fit: cover;
				@extend %center;
				display: block;
				width: 100%;
				height: 100%;

				&.contain {
					object-fit: contain;
				}
			}
		}
	}
}
