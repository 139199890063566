.site_sidebar {
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	position: fixed;
	min-width: 4rem;
	width: 4rem;
	max-width: 4rem;
	z-index: 9001;

	&.on {
		height: 100vh;
	}

	@media (min-width: $breakpoint_to_full_sidebar) {
		min-width: 15.625rem;
		width: 15.625rem;
		max-width: 15.625rem;
		height: 100vh;
	}

	@media only print {
		position: relative;
		height: auto;
	}

	a.logo {
		flex: 0 0 auto;
		height: 4.25rem;
		padding: 1.25rem .75rem;
		display: none;

		@media (min-width: $breakpoint_to_full_sidebar) {
			padding: .875rem 1rem;
			display: block;
		}

		svg {
			&:nth-of-type(1) {
				display: block;

				@media (min-width: $breakpoint_to_full_sidebar) {
					display: none;
				}
			}

			&:nth-of-type(2) {
				display: none;

				@media (min-width: $breakpoint_to_full_sidebar) {
					display: block;
					margin: 0 auto;
				}
			}
		}
	}

	> .toggle_nav {
		flex: 0 0 auto;
		height: 4.3rem;
		padding: 1.25rem;
		padding-top: 1.5rem;
		background-color: #fff;
		box-shadow: none;
		border-bottom: 1px solid $color_alto;
		// background-image: linear-gradient(to bottom, $color_east_bay 0%, $color_waterloo 100%);

		&:hover,&:focus {
			svg path {
				transition: all .25s;
				fill: $color_carrot_orange;
			}
		}
		@media (min-width: $breakpoint_to_full_sidebar) {
			display: none;
		}

		svg {
			path {
				transition: all .25s;
				fill: $color_comet;
			}
			mask {
				display: none;
			}
		}

		@media only print {
			display: none;
		}
	}

	> section {
		flex: 1 1 auto;
		position: absolute;
		top: 4.25rem;
		width: 100vw;
		min-height: calc(100vh - 4.25rem);
		max-height: calc(100vh - 4.25rem);
		padding-bottom: 12rem;
		background-image: url('../../svgs/portal_sidebar_php.svg'), linear-gradient(to bottom, $color_east_bay 0%, $color_waterloo 100%);
		background-position: 50% 102%, 100%;
		background-size: 120%, 100%;
		background-repeat: no-repeat, no-repeat;
		background-attachment: scroll, scroll;
		z-index: -1;
		transition: .25s all;
		transform: translateX(-100%);
		opacity: 0;

		@media (min-width: $breakpoint_to_full_sidebar) {
			overflow-y: visible;
			opacity: 1;
		}

		@media only print {
			display: none;
		}

		&.on {
			z-index: 9001;
			transform: translateX(0);
			overflow-y: auto;
			opacity: 1;

			@media (min-width: $breakpoint_to_full_sidebar) {
				overflow-y: visible;
			}
		}

		@media (min-width: $breakpoint_to_full_sidebar) {
			position: relative;
			top: auto;
			width: auto;
			height: auto;
			transform: translateX(0);
		}

		.toggle_nav {
			display: block;
			padding: 1.25rem 1.5rem;
			border-bottom: 1px solid $color_martinique2;

			@media (min-width: $breakpoint_to_full_sidebar) {
				display: none;
			}

			svg {
				display: block;
			}
		}

		nav {
			width: 100%;

			button{
				background-color: transparent;
				border: 0;
				outline: 0;
				border-radius: 0;

				&:hover {

					background-color: #2d2a46;
				}
			}

			ul {
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					display: flex;
					flex-wrap: wrap;
					margin-bottom: 0;
					border-bottom: 1px solid $color_martinique2;
					list-style-type: none;

					&.dashboard-button {
						display: block;
						@media (min-width: $breakpoint_to_full_sidebar) {
							display: none;
						}
					}

					&.onboard {
						background-color: $color_martinique;
					}

					&.active {
						//background-color: $color_carrot_orange;
						//border-color: $color_carrot_orange;

						> a:not(.toggle_subnav) {
							background-color: $color_carrot_orange;
						}

						a:not(.toggle_subnav) {
							&:hover,
							&:focus {
								border-bottom-color: $color_martinique;
							}
						}

						a.toggle_subnav {
							border-left: $color_carrot_orange;
						}
					}

					a:not(.toggle_subnav) {
						flex: 1 1 auto;
						display: flex;
						justify-content: space-between;
						align-items: center;
						position: relative;
						padding: 1rem;
						color: $color_white;
						font-weight: 600;
						font-size: .875rem;
						letter-spacing: .025em;
						text-decoration: none;
						text-transform: uppercase;
						border-left: 4px solid transparent;

						&:hover,
						&:focus,
						&.on {
							background-color: $color_martinique;
							border-left-color: $color_carrot_orange;
						}
					}

					.toggle_subnav {
						flex: 0 1 auto;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 1rem;
						border-left: 1px solid $color_martinique2;

						svg {
							width: .75rem;
							height: .75rem;
							transform: rotate(-90deg);

							path {
								fill: $color_white;
							}
						}

						&:hover,
						&:focus {
							background-color: $color_martinique;
						}
					}

					.subnav {
						flex: 1 0 100%;
						display: none;
						background-color: $color_martinique;

						@media (min-width: $breakpoint_to_full_sidebar) {
							position: absolute;
							top: 0;
							bottom: 0;
							left: 100%;
							min-width: 15.625rem;
							max-width: 20rem;
							box-shadow: .25rem .125rem .25rem rgba($color_black, .32);
						}

						> div {
							display: block;
							padding-right: 0;
							padding-left: 0;

							header {
								padding: 1rem 1.25rem 0 1.25rem;
								color: $color_white;
								font-size: 1.125rem;
								letter-spacing: .04556em;
								text-transform: uppercase;
							}

							> p {
								margin-bottom: 1.5rem;
								padding: 0 1.25rem;
								color: $color_white;
								font-weight: 300;
								font-size: .875rem;
								//letter-spacing: .05857em;
								text-transform: none;
							}
						}

						ul {
							list-style: none;
							list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); // Fix IE/Edge
							margin: 0;
							//margin-top: 3rem;
							padding: 0;
							border-top: 1px solid $color_comet;

							li {
								a {
									display: block;
									text-transform: none;
									//white-space: nowrap;
									border-bottom: 1px solid $color_comet;
								}
							}
						}
					}
				}
			}

			.hr_assessment {
				padding: 1.5rem;
				color: $color_white;

				header {
					margin-top: 1rem;
					margin-bottom: 1rem;
					letter-spacing: .058125em;

					span {
						&:after {
							content: '%';
						}
					}
				}

				.progress_bar {
					height: .75rem;
					background: $color_white;
					border-radius: 1000rem;

					.progress {
						min-width: .75rem;
						width: 66%;
						max-width: 100%;
						height: .75rem;
						background-image: linear-gradient(to right, $color_vida_loca 0%, $color_conifer 100%);
						border-radius: 1000rem;
					}
				}

				p {
					font-size: .875rem;
					letter-spacing: .05857em;
					line-height: 1.21429;
				}

				.note {
					font-style: italic;
				}
			}

			.eligible {
				background-color: $color_carrot_orange;
			}
		}

		.bg_logo {
			position: absolute;
			right: 0;
			bottom: 0;
			left: 0;
			display: none;
			overflow: hidden;
			z-index: -1;

			svg {
				position: relative;
				top: 1.5rem;
				left: 50%;
				display: block;
				transform: translateX(-50%);
			}
		}
	}
}
