/**
 *		Scale units gracefully on any property that supports calc()
 *
 *		element/.class/#id {
 *			// Scales font-size from 16px (at 320px) to 24px (at 640px)
 *			@include scale(font-size, 1rem, 20rem, 1.5rem, 40rem);
 *		}
 */
@mixin scale($prop, $start-size, $start-width, $end-size, $end-width) {
	#{$prop}: #{$start-size};
	@media (min-width: #{$start-width}) {
		#{$prop}: calc(#{$start-size} + #{strip-unit($end-size - $start-size)} * (100vw - #{$start-width}) / #{strip-unit($end-width - $start-width)});
	}
	@media (min-width: #{$end-width}) {
		#{$prop}: #{$end-size};
	}
}

/**
 *		flexbox / inline-flexbox
 *
 *		element/.class/#id {
 *			@include flexbox();
 *		}
 */
@mixin flexbox($layout: 'block') {
	@if $layout == 'inline' {
		display: -webkit-inline-box; // Safari 3.1+, iOS Safari 3.2+, Chrome 4+
		display: -moz-inline-box; // Firefox 2+
		display: -ms-inline-flexbox; // IE 10
		display: -webkit-inline-flex; // Safari 6.1+, iOS Safari 7.1+
		display: inline-flex; // Safari 9+, iOS Safari 9.2+, Firefox 22+, IE 11+, Chrome 29+
	} @else {
		display: -webkit-box; // Safari 3.1+, iOS Safari 3.2+, Chrome 4+
		display: -moz-box; // Firefox 2+
		display: -ms-flexbox; // IE 10
		display: -webkit-flex; // Safari 6.1+, iOS Safari 7.1+
		display: flex; // Safari 9+, iOS Safari 9.2+, Firefox 22+, IE 11+, Chrome 29+
	}
}

/**
 *		flex-direction
 *
 *		element/.class/#id {
 *			@include flex-direction();
 *		}
 */
@mixin flex-direction($val) {
	@if $val == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $val == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $val == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $val;
	-moz-flex-direction: $val;
	-ms-flex-direction: $val;
	flex-direction: $val;
}

/**
 *		flex
 *
 *		element/.class/#id {
 *			@include flex();
 *		}
 */
@mixin flex($val) {
	-webkit-box-flex: $val;
	-moz-box-flex: $val;
	-webkit-flex: $val;
	-ms-flex: $val;
	flex: $val;
}

/**
 *		flex-wrap
 *
 *		element/.class/#id {
 *			@include flex-wrap();
 *		}
 */
@mixin flex-wrap($val) {
	-webkit-flex-wrap: $val;
	-moz-flex-wrap: $val;
	@if $val == nowrap {
		-ms-flex-wrap: none;
	} @else {
		-ms-flex-wrap: $val;
	}
	flex-wrap: $val;
}

/**
 *		justify-content
 *
 *		element/.class/#id {
 *			@include justify-content();
 *		}
 */
@mixin justify-content($val) {
	@if $val == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $val == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $val == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $val == space-around {
		-ms-flex-pack: distribute;
	} @else {
		-webkit-box-pack: $val;
		-ms-flex-pack: $val;
	}
	-webkit-justify-content: $val;
	-moz-justify-content: $val;
	justify-content: $val;
}

/**
 *		align-items
 *
 *		element/.class/#id {
 *			@include align-items();
 *		}
 */
@mixin align-items($val) {
	@if $val == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $val == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $val;
		-ms-flex-align: $val;
	}
	-webkit-align-items: $val;
	-moz-align-items: $val;
	align-items: $val;
}

/**
 *		order
 *
 *		element/.class/#id {
 *			@include order();
 *		}
 */
@mixin order($val) {
	-webkit-box-ordinal-group: $val;
	-moz-box-ordinal-group: $val;
	-ms-flex-order: $val;
	-webkit-order: $val;
	order: $val;
}

/**
 *		transform
 *
 *		element/.class/#id {
 *			@include transform();
 *		}
 */
@mixin transform($val) {
	-webkit-transform: $val; // Chrome 4+, Safari 3.1+, iOS Safari 3.2+
	-moz-transform: $val; // Firefox 3.5+
	-ms-transform: $val; // IE 9
	-o-transform: $val; // Opera 11.5+
	transform: $val; // Chrome 36+, Safari 9+, iOS Safari 9.2+, Firefox 16+, IE 10+, Opera 23+
}

/**
 *		Generate column-based layouts using flexbox
 *
 *		@include on the parent element and all direct children (of any type)
 *		will form a column layout at the specified breakpoints
 *
 *		element/.class/#id {
 *			// Forms a 2-column layout at 480px, 3-columns at 640px,
 *			// jumps back to 2 columns at 800px, etc.
 *			@include columns($margin_columns, $margin_rows, (
 *				30em : 2,
 *				40em : 3,
 *				50em : 2,
 *				60em : 3,
 *				70em : 4
 *			));
 *		}
 */
@mixin columns($margin_columns, $margin_rows, $breakpoint_map) {
	display: flex;
	flex-wrap: wrap;
	@if $margin_columns == 0 {
		margin-right: 0;
		margin-left: 0;
	} @else {
		margin-right: -#{$margin_columns / 2};
		margin-left: -#{$margin_columns / 2};
	}

	> * {
		display: block;
		@if $margin_columns == 0 {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		} @else {
			width: calc(100% - #{$margin_columns});
			margin-right: #{$margin_columns / 2};
			margin-left: #{$margin_columns / 2};
		}
		margin-bottom: $margin_rows;

		@each $breakpoint, $num_columns in $breakpoint_map {
			@media (min-width: $breakpoint) {
				flex: 1 1 auto;
				@if $margin_columns == 0 {
					width: calc(100% / #{$num_columns}.0001);
					max-width: calc(100% / #{$num_columns}.0001);
				} @else {
					width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
					max-width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
				}
			}
		}
	}

	@supports (display: grid) {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: $margin_columns;
		grid-row-gap: $margin_rows;
		margin-right: 0;
		margin-bottom: $margin_rows;
		margin-left: 0;

		@each $breakpoint, $num_columns in $breakpoint_map {
			$val: 1fr;
			// Loop from 2 through $num_columns since $val already has a value of '1fr'
			@for $i from 2 through $num_columns {
				$val: append($val, 1fr, space);
			}
			@media (min-width: $breakpoint) {
				grid-template-columns: $val;
			}
		}

		> * {
			min-width: 0;
			width: auto;
			max-width: none;
			margin-right: 0;
			margin-bottom: 0;
			margin-left: 0;
		}
	}
}

/**
 *		scrim-gradient
 *
 *		- Customizes the color stops for a much smoother transition
 *		- ONLY works from a single color to transparent
 *		- See https://css-tricks.com/easing-linear-gradients/ for details
 *
 *		element/.class/#id {
 *			@include scrim-gradient($direction, $color);
 *		}
 */
@mixin scrim-gradient($direction, $color) {
	background-image: linear-gradient($direction,
		$color 0%,
		rgba($color, .738) 19%,
		rgba($color, .541) 34%,
		rgba($color, .382) 47%,
		rgba($color, .278) 56.5%,
		rgba($color, .194) 65%,
		rgba($color, .126) 73%,
		rgba($color, .075) 80.2%,
		rgba($color, .042) 86.1%,
		rgba($color, .021) 91%,
		rgba($color, .008) 95.2%,
		rgba($color, .002) 98.2%,
		rgba($color, 0) 100%);
}

/**
 *		Browser hack wrapper
 *
 *		- supports ie11, edge
 *
 *		@include browser(ie11, edge) {
 * 			// ie11 and edge browser-specific rules
 * 		}
 */
@mixin _browser-ie11 { @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { @content; } }
@mixin _browser-edge { @supports (-ms-ime-align: auto) { @content; } }
@mixin browser ($queries...) {
	@each $q in $queries {
		     @if $q == 'ie11' { @include _browser-ie11 { @content; } }
		@else if $q == 'edge' { @include _browser-edge { @content; } }
	}
}

/**
 *		filter
 *
 *		element/.class/#id {
 *			@include filter();
 *		}
 */
@mixin filter($val) {
	-webkit-filter: $val; // Chrome 18+, Opera 15+, Safari 6+, iOS Safari 6.1+
	filter: $val; // Firefox 35+, Chrome 53+, Opera 40+, Safari 9.1+, iOS Safari 9.3+, Edge 13+ [no support for url()]
}
/**
 *		transition
 *
 *		element/.class/#id {
 *			@include transition();
 *		}
 */
@mixin transition($val...) {
	-webkit-transition: $val; // Chrome 1+, Safari 3.1+, iOS Safari 3.2+
	-moz-transition: $val; // Firefox 4+
	-o-transition: $val; // Opera 10.1+
	transition: $val; // Chrome 26+, Firefox 16+, IE 10+, Opera 12.1+, Safari 6.1+, iOS Safari 7.1+
}

/**
 *		object-fit	[Use https://github.com/bfred-it/object-fit-images/ polyfill for IE, Edge, Android < 5, Safari < 10, etc.]
 *
 *		element/.class/#id {
 *			@include object-fit();
 *		}
 */
@mixin object-fit($val, $position: null) {
	-o-object-fit: $val; // Opera Mini/Mobile 12+
	object-fit: $val; // Opera Mobile, everything else
	@if $position {
		-o-object-position: $position;
		object-position: $position;
		font-family: 'object-fit: #{$val}; object-position: #{$position}';
	} @else {
		font-family: 'object-fit: #{$val}';
	}
}

/**
 *		Appearance
 *
 *		element/.class/#id {
 *			@include appearance();
 *		}
 */
@mixin appearance($val: none) {
	-webkit-appearance: $val; // Chrome 1+, Safari 3.1+, iOS Safari 3.2+, Opera 15+, Edge 12+
	-moz-appearance: $val; // Firefox 1+
	appearance: $val;
	&::-ms-expand {
		display: none;
	}
}

/**
 *		box-shadow
 *
 *		element/.class/#id {
 *			@include box-shadow();
 *		}
 */
@mixin box-shadow($val...) {
	-webkit-box-shadow: $val; // Chrome 4+, Safari 3.1+, iOS Safari 3.2+
	-moz-box-shadow: $val; // Firefox 3.5+
	box-shadow: $val; // Chrome 10+, Safari 5.1+, iOS Safari 5.1+, Firefox 4+, Opera 11.5+, IE9+
}

/**
 *		break-inside
 *
 *		element/.class/#id {
 *			@include break-inside();
 *		}
 */
@mixin break-inside($val: auto) {
	-webkit-column-break-inside: $val;
	page-break-inside: $val;
	break-inside: $val;
}