.button:not(.ignore-button) {
	display: inline-block;
	min-width: 9.75rem;
	margin-top: 2.5rem;
	padding: .6875rem 2.5rem;
	color: white;
	font-weight: 600;
	letter-spacing: .034375em;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background-color: $color_secondary_orange;
	border: 0;
	border-radius: 4px;

	&.hover,
	&:hover,
	&:focus {
		color: white;
		background-color: $color_secondary_purple;
	}

	&.onboarding {
		background-color: #82c056;

		&.hover,
		&:hover,
		&:focus {
			background-color: #5e9736;
		}
	}

	&.hero {
		color: #6a737b;
		background-color: white;

		&.hover,
		&:hover,
		&:focus {
			color: white;
			background-color: $color_secondary_orange;
		}
	}

	&.accessibility {
		width: 100%;
		max-width: 32.625rem;
		margin-bottom: 1.75rem;
		padding: 1rem 2.5rem;
		color: #959595;
		font-size: 1.25rem;
		background-color: white;
		box-shadow: $drop_shadow;

		&.active {
			color: white;
			background-color: #82c056;

			&.hover,
			&:hover,
			&:focus {
				background-color: #5e9736;
			}
		}
	}
}

a.button {
	display: inline-block;
	min-width: 10rem;
	padding: .625rem 1.5rem;
	color: $color_button;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	background-color: $color_button_background;
	border: 2px solid $color_button_border;

	&:hover,
	&:focus {
		color: $color_button_hover;
		background-color: $color_button_background_hover;
		border-color: $color_button_border_hover;
	}
}
