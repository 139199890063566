//@import '../../../node_modules/php0616-design-system/httpdocs/assets/scss/design-system';

body {
	color: $color_body;
	font-family: $font_stack_sans_serif;
	line-height: 1.75;
	overflow-x: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	&[data-fonts-loaded=true] {
		font-family: $font_family_body;
	}

	#modal-overlay, .modal-overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;
		background-color: rgba($color_secondary_purple, .85);
		z-index: 999999;

		&.on {
			@include flexbox();
			@include justify-content(center);
			@include align-items(center);
		}
		.modal {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 92%;
			max-width: 37.5rem;
			background-color: white;
			border-radius: 8px;
			box-shadow: 0 2px 4px 1px rgba(black, .5);
			transform: translate(-50%, -50%);

			.content {
				padding-top: 1.5rem;
				@include scale(padding-right, 1rem, 20rem, 2.5rem, 60rem);
				padding-bottom: 1.5rem;
				@include scale(padding-left, 1rem, 20rem, 2.5rem, 60rem);
				text-align: center;

				header {
					color: $color_secondary_purple;
					@include scale(font-size, 1.25rem, 20rem, 1.625rem, 60rem);
				}
			}

			.button {
				width: 100%;
				margin-top: 0;
				padding-top: 1rem;
				padding-bottom: 1rem;
				@include scale(font-size, 1rem, 20rem, 1.375rem, 60rem);
				text-transform: none;
				border-radius: 0 0 8px 8px;
			}
		}
	}

	&.modal-is-shown {
		overflow: hidden;
		cursor: wait;

		#modal-overlay {
			display: block;
		}
	}
}

a {
	color: $color_hyperlink;
	font-weight: 500;
	text-decoration: none;
	transition: all .25s;

	&:link {}

	&:visited {}

	&.hover,
	&:hover,
	&:focus {
		color: $color_hyperlink_hover;
	}

	&:active {}
}

h1, h2, h3, h4, h5, h6 {
	margin: 1rem 0;
	color: $color_secondary_purple;
	font-weight: 400;
	line-height: 1.2em;

	@at-root body[data-fonts-loaded=true] & {
		font-family: $font_family_header;
	}
}

h1 {
	font-weight: 600;
	@include scale(font-size, $font_size_h1 - .625, 20em, $font_size_h1, 32em);
	letter-spacing: .011em;
}

h2 {
	@include scale(font-size, $font_size_h2 - .5625, 20em, $font_size_h2, 32em);
	letter-spacing: .014em;
}

h3 {
	@include scale(font-size, $font_size_h3 - .4375, 20em, $font_size_h3, 32em);
	letter-spacing: .018em;
}

h4 {
	@include scale(font-size, $font_size_h4 - .25, 20em, $font_size_h4, 32em);
	letter-spacing: .021em;
}

h5 {
	@include scale(font-size, $font_size_h5 - .125, 20em, $font_size_h5, 32em);
	letter-spacing: .025em;
}

h6 {
	@include scale(font-size, $font_size_h6 - .125, 20em, $font_size_h6, 32em);
	letter-spacing: .028em;
}

blockquote {
	position: relative;
	margin: 0 0 4rem;
	color: $color_blockquote;
	font-family: $font_family_blockquote;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 4px;
		background-image: linear-gradient(to bottom, $gradient_green_left 0%, $gradient_green_right 100%);
	}

	p {
		margin-left: 1.5rem;
		font-style: italic;
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 1.6em;
	}
}

ul, ol {
	margin: 1rem 0;

	li:not(:last-of-type) {
		margin-bottom: .25rem;
	}
}


[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
display: none !important;
}

html {
	height: 100%;
}

body {
	display: flex;
	min-height: 100%;
	color: $color_body;
	font-family: $font_stack_sans_serif;
	line-height: 1.5;
	overflow-x: hidden;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	@media only print {
		display: block;
		font-size: .875rem;
	}

	&.is_fixed {
		overflow-y: hidden;
	}

	&.stop-scrolling {
		height: auto !important;
	}

	&[data-fonts-loaded=true] {
		font-family: $font_family_body;
	}

	#loading-overlay {
		display: none;

		div {
			@extend %center;

			.spinner {
				display: block;
				animation: rotator $loading_spinner_duration linear infinite;

				.path {
					stroke-dasharray: $loading_spinner_offset;
					stroke-dashoffset: 0;
					transform-origin: center;
					animation: dash $loading_spinner_duration ease-in-out infinite, colors ($loading_spinner_duration * 4) ease-in-out infinite;
				}
			}
		}
	}

	&.is-loading {
		overflow: hidden;
		cursor: wait;

		#loading-overlay {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: block;
			background-color: rgba($color_white, .75);
			cursor: wait;
			z-index: 999999;
		}
	}
}

a {
	color: $color_hyperlink;
	transition: all .25s;

	&:link {}

	&:visited {}

	&:hover,
	&:focus {
		color: $color_hyperlink_hover;
	}

	&:active {}
}

body[data-fonts-loaded=true] {
	h1, h2, h3, h4, h5, h6 {
		font-family: $font_family_header;
	}
}

h1 {
	@include scale(font-size, $font_size_h1 - .625, 20em, $font_size_h1, 32em);
	letter-spacing: .011em;
}

h2 {
	@include scale(font-size, $font_size_h2 - .5625, 20em, $font_size_h2, 32em);
	letter-spacing: .014em;
}

h3 {
	@include scale(font-size, $font_size_h3 - .5, 20em, $font_size_h3, 32em);
	letter-spacing: .018em;
}

h4 {
	@include scale(font-size, $font_size_h4 - .4375, 20em, $font_size_h4, 32em);
	letter-spacing: .021em;
}

h5 {
	@include scale(font-size, $font_size_h5 - .375, 20em, $font_size_h5, 32em);
	letter-spacing: .025em;
}

h6 {
	@include scale(font-size, $font_size_h6 - .3125, 20em, $font_size_h6, 32em);
	letter-spacing: .028em;
}

blockquote {
	quotes: "\201C" "\201D" "\201C" "\201D";
	position: relative;
	margin: 0 5vw 4rem 5vw;
	color: $color_blockquote;
	font-family: $font_family_blockquote;

	&:before {
		content: open-quote;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}

	p {
		margin-left: 4rem;
	}
}

ul, ol {
	li {

	}
}

.force-align {
	display: flex;
	align-items: center;
	white-space: nowrap;

	label {
		margin: 0;
	}

	span + label,
	label + span {
		margin-left: .5rem;
	}
}

.center, .swal-text {
    text-align: center;
}

.top-spacing {
	padding: 1.5rem;
}

.bold {
	font-weight: 700;
}

.red {
	color: #c7031e;
}

.small-text {
	font-size: 0.75rem;
}

