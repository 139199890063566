.sitewide-alert {
	position: relative;
	display: flex;
	width: 100%;
	background: url(/assets/images/sitewide-alert-background.png) calc(100% - 3rem) calc(100% + 49px) / 148px 98px no-repeat scroll;

	&.blue {
		background-color: #4a90e2;
	}

	&.green {
		background-color: $color_primary_green;
	}

	&.red {
		background-color: #d0021b;
	}

	&.orange {
		background-color: $color_secondary_orange;
	}

	.msg {
		flex: 1 1 100%;
		padding: .8125rem 1.5rem;
		color: white;
		text-decoration: none;

		span:first-of-type {
			font-weight: 600;
		}

		span:last-of-type {
			font-weight: 400;
			font-size: .875rem;
		}
	}

	.close {
		flex: 0 0 3.375rem;
		position: relative;
		width: 3.375rem;
		background-color: rgba(black, .3);

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.content-alert {
	position: relative;
	display: flex;
	width: 100%;
	border-radius: 6px;
	overflow: hidden;

	.icon {
		flex: 0 0 4rem;
		position: relative;
		width: 4rem;
		background-color: rgba(black, .3);

		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			max-width: 1.875rem;
			transform: translate(-50%, -50%);

			path {
				fill: white;
			}
		}
	}

	.right {
		display: flex;
		align-items: center;

		.content {
			flex: 1 1 100%;
			padding: 1rem 1.5rem;
			color: white;
			font-size: .875rem;

			header {
				font-weight: 600;
				font-size: 1.25rem;
			}
		}

		.link {
			padding: 1rem 1.5rem;

			a {
				padding: .625rem 2rem;
				font-weight: 600;
				font-size: .875rem;
				white-space: nowrap;
				background-color: white;
				border-radius: 100rem;
			}

			span {
				display: block;
				margin-top: .75rem;
				color: white;
				font-size: .625rem;
				text-align: center;
			}
		}
	}

	&.background {
		background: url(/assets/images/content-alert-background.png) 50% 50% / 176px 166px no-repeat scroll;
	}

	&.orange {
		background-color: $color_secondary_orange;
	}

	&.green {
		background-color: $color_primary_green;

		.right .link a {
			color: $color_primary_green;
		}
	}

	&.purple {
		background-color: $color_secondary_purple;

		.right .link a {
			color: $color_secondary_orange;
		}
	}
}

#alert {
	position: relative;
	display: block;
	width: 100%;
	padding: .75rem 1.5rem;
	color: $color_white;
	font-size: .875rem;
	letter-spacing: .05em;
	text-align: center;
	font-weight: 600;
	text-decoration: none;

	@media only print {
		display: none;
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex: 1 0 auto; // ie 11 fix
	}

	&.urgent {
		background-color: $color_urgent_background;
	}

	&.informational {
		background-color: $color_informational_background;
	}

	&.conditional {
		background-color: $color_carrot_orange;
	}

	span {
		display: inline-block;
		line-height: 1.5;
	}

	svg {
		fill: rgba($color_white, .9);
		position: relative;
		top: .125rem;
		width: .9375rem;
		height: .9375rem;
		margin-right: .625rem;
	}
}