/**
 *		Project (style guide)
 */
$color_primary_purple						: #2e2750;
$color_primary_green						: #7cbf4b;
$color_secondary_purple						: #5d5681;
$color_secondary_orange						: #ed822b;
$color_secondary_gray						: #6a737b;
$color_secondary_yellow						: #fec221;

$gradient_purple_right						: #d7d5e0;
$gradient_purple							: linear-gradient(to right, $color_secondary_purple 0%, $gradient_purple_right 100%);
$gradient_green_left						: #96cc6d;
$gradient_green_right						: #e5f2da;
$gradient_green								: linear-gradient(to right, $gradient_green_left 0%, $gradient_green_right 100%);

$drop_shadow								: 0 10px 15px #dbdbdb;

$font_stack_serif							: "Lucida Bright", Georgia, TimesNewRoman, "Times New Roman", Times, Baskerville, serif;
$font_stack_sans_serif						: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font_stack_cursive							: cursive;
$font_stack_fantasy							: fantasy;
$font_stack_monospace						: monospace;

$font_family_open_sans						: 'Open Sans', $font_stack_sans_serif;

$color_jacarta								: #362e64;
$color_comet								: #5d5681;
$color_carrot_orange						: #ed822b;
$color_nevada								: #6a737b;
$color_mantis								: #7cbf4b;
$color_dove_gray							: #717171;
$color_jaffa								: #f38034;
$color_smoky								: #565079;
$color_pale_sky								: #6b747b;

$color_timber_green							: #1c2e36;
$color_silver								: #ccc;
$color_silver_chalice						: #a4a4a4;
$color_monza								: #d0021b;
$color_steel_gray							: #242230;
$color_gray									: #858585;
$color_swiss_coffee							: #dbd4d4;
$color_kimberly								: #746e96;
$color_outer_space							: #383e43;
$color_froly								: #ef6273;
$color_mulled_wine							: #4c4671;
$color_dusty_gray							: #959595;
$color_matrix								: #b45b66;
$color_scorpion								: #595959;
$color_dolphin								: #656082;
$color_athens_gray							: #ecebf0;
$color_rum									: #6c6688;
$color_charade								: #2b2a35;
$color_east_bay								: #433d6a;
$color_waterloo								: #8580a1;
$color_port_gore							: #2e2750;
$color_martinique							: #2d2a46;
$color_martinique2: #38354c;
$color_conifer: #b4ec51;
$color_vida_loca: #429321;
$color_sushi: #74b943;
$color_bittersweet: #fd6a6a;
$color_emporer: #555;
$color_boulder: #7a7777;
$color_aqua_haze: #f2f5f8;
$color_slate_gray: #75828d;

$font_stack_serif: "Lucida Bright", Georgia, TimesNewRoman, "Times New Roman", Times, Baskerville, serif;
$font_stack_sans_serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font_stack_cursive: cursive;
$font_stack_fantasy: fantasy;
$font_stack_monospace: monospace;

$font_family_open_sans: 'Open Sans', $font_stack_sans_serif;

$breakpoint_to_full_sidebar_px: 864px;
$breakpoint_to_full_sidebar: 40em;

/**
 *		Standards (feel free to add, but don't delete existing!)
 */
$color_black: #000;
$color_white: #fff;

$color_alabaster: #fafafa;
$color_alabaster2: #f7f7f7;
$color_alto: #dbdbdb;
$color_cornflower_blue: #4285f4;
$color_eucalyptus: #1b9a59;
$color_gallery: #efefef;
$color_lightning_yellow: #f7c223;
$color_mercury: #e5e5e5;
$color_mine_shaft: #323232;
$color_punch: #de3e35;
$color_regent_gray: #86949f;
$color_silver: #c0c0c0;
$color_silver_chalice: #acacac;
$color_tamarillo: #991613;
$color_wild_sand: #f4f4f4;

$color_success: #7cbf4b;
$color_error: #c7031e;

$color_facebook: #3b5998;
$color_flickr: #0063dc;
$color_google_plus: #dc4e41;
$color_instagram: #e4405f;
$color_linkedin: #0077b5;
$color_pinterest: #bd081c;
$color_twitter: #1da1f2;
$color_vimeo: #1ab7ea;
$color_yelp: #c41200;
$color_youtube: #ff0000;

/**
 *		Base elements
 */
$color_body: $color_dove_gray;
$color_hyperlink: $color_carrot_orange;
$color_hyperlink_hover: $color_comet;

$font_family_body: $font_family_open_sans;
$font_family_header: $font_family_open_sans;

$font_size_h1: 2.375rem;
$font_size_h2: 2rem;
$font_size_h3: 1.75rem;
$font_size_h4: 1.5rem;
$font_size_h5: 1.25rem;
$font_size_h6: 1.125rem;

//	Sitewide alerts
$color_urgent_background					: $color_error;
$color_informational_background				: $color_success;

//	Menu
$color_menu_borders							: $color_regent_gray;
$color_menu									: $color_black;
$color_menu_hover							: $color_white;
$color_menu_background						: $color_white;
$color_menu_background_hover				: $color_regent_gray;

//	Blockquotes
$color_blockquote							: $color_comet;
$font_family_blockquote						: $font_family_open_sans;

//	Button Links
$color_button								: $color_black;
$color_button_hover							: $color_white;

$color_button_background					: $color_silver;
$color_button_background_hover				: darken($color_silver, 50);

$color_button_border						: $color_silver;
$color_button_border_hover					: darken($color_silver, 50);

/**
 *		Tables
 */
$color_table_borders						: $color_alto;
$color_table_header_background				: $color_wild_sand;
$color_table_alternate_rows					: $color_wild_sand;

$font_family_table_headers					: $font_family_body;

/**
 *		Forms
 */
$color_form_errors							: $color_error;
$color_form_success							: $color_success;

$color_form_pages_text						: $color_white;
$color_form_pages_background				: $color_silver_chalice;

$color_input_text							: $color_body;
$color_input_placeholder					: $color_silver_chalice;
$color_input_border							: $color_silver;
$color_input_border_disabled				: #eee;
$color_input_border_focus					: $color_black;
$color_input_border_error					: $color_error;
$color_input_border_complete				: $color_success;
$input_border_thickness						: 1px;
$input_border_radius						: 3px;

$color_checkbox_background					: $color_black;
$color_radio_background						: $color_black;

$font_family_labels							: $font_family_body;
$font_family_instructions					: $font_family_body;
$font_family_legends						: $font_family_body;

/**
 *		Store (cart, checkout, etc)
 */
// Steps
$color_cart_steps_background				: $color_alto;
$color_cart_steps_background_active			: $color_mine_shaft;
$font_family_steps_labels					: $font_family_body;
$breakpoint_cart_steps_to_horizontal		: 32em;

// Cart
$color_cart_items_border					: $color_alto;
$color_cart_discount						: $color_tamarillo;

/**
 *		Column layouts
 */
$breakpoint_col2							: 40em; // Break to 2 columns (based on 960px width, use 50em for 1280px)

$breakpoint_col3_to2						: 35em; // Break to 2 columns (based on 960px width, use 40em for 1280px)
$breakpoint_col3_to3						: 50em; // Break to 3 columns (based on 960px width, use 60em for 1280px)

$breakpoint_col4_to2						: 30em; // Break to 2 columns (based on 960px width, use 35em for 1280px)
$breakpoint_col4_to3						: 40em; // Break to 3 columns (based on 960px width, use 50em for 1280px)
$breakpoint_col4_to4						: 50em; // Break to 4 columns (based on 960px width, use 65em for 1280px)

$breakpoint_col5_to2						: 28em; // Break to 2 columns (based on 960px width, use 32em for 1280px)
$breakpoint_col5_to3						: 36em; // Break to 3 columns (based on 960px width, use 44em for 1280px)
$breakpoint_col5_to4						: 44em; // Break to 4 columns (based on 960px width, use 56em for 1280px)
$breakpoint_col5_to5						: 52em; // Break to 5 columns (based on 960px width, use 68em for 1280px)

$margin_columns								: 1.5rem; // Whitespace between columns
$margin_rows								: 1.5rem; // Whitespace between rows

/**
 *		Bloqs
 */
$bloq_overlay_color							: $color_black;
$bloq_overlay_text_color					: $color_white;
$bloq_overlay_text_color_hover				: $color_white;
$bloq_overlay_text_shadow_color				: $color_black;
$bloq_icon_color							: $color_white;
$bloq_icon_shadow_color						: $color_black;
$bloq_media_spacing							: 1.5rem;
$bloq_paragraph_spacing						: 1.5rem;

/**
 *		Loading overlay
 */
$loading_spinner_color1						: $color_cornflower_blue;
$loading_spinner_color2						: $color_punch;
$loading_spinner_color3						: $color_lightning_yellow;
$loading_spinner_color4						: $color_eucalyptus;
$loading_spinner_offset						: 187;
$loading_spinner_duration					: 1.4s;