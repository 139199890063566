$breakpoint_to_desktop_nav : 60em;
.site-header {
	.site-nav {
		@extend %clearfix;
		position: relative;
		background-color: $color_white;
		border-bottom: 1px solid $color_menu_borders;
		z-index: 100;

		.toggle.menu {
			float: right;
			display: block;
			padding: .6875rem;
			text-decoration: none;
			cursor: pointer;

			@media (min-width: $breakpoint_to_desktop_nav) {
				display: none;
			}

			svg {
				transition: all .25s;
				fill: $color_menu;
				display: block;
				width: 1.625rem;
				height: 1.625rem;
			}
		}
	}

	.site-nav .toggle.menu.on svg,
	.site-nav .toggle.menu:hover svg,
	.site-nav .toggle.menu:focus svg {
		fill: $color_black;
	}

	.site-menu {
		position: absolute;
		top: 100%;
		left: 0;
		display: none;
		width: 100%;
		background-color: $color_menu_background;
		border-bottom: 1px solid $color_menu_borders;
		z-index: 110;

		@media (min-width: $breakpoint_to_desktop_nav) {
			position: relative;
			top: auto;
			display: block !important;
			width: auto;
			border-bottom: 0;
			overflow: visible !important;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				@extend %clearfix;
				display: block;
				margin: 0;

				@media (min-width: $breakpoint_to_desktop_nav) {
					display: inline-block;

					&.on {
						color: $color_white;
						background-color: $color_menu_background_hover;
					}
				}

				&.has-children {
					& > a:not(.toggle) {
						float: left;
						width: calc(100% - 3rem);

						@media (min-width: $breakpoint_to_desktop_nav) {
							float: none;
							width: 100%;
						}
					}

					a.toggle {
						transition: all .25s;
						float: right;
						position: relative;
						width: 3rem;
						height: 3rem;
						border-bottom: 1px solid $color_menu_borders;
						border-left: 1px solid $color_menu_borders;
						cursor: pointer;

						@media (min-width: $breakpoint_to_desktop_nav) {
							display: none;
						}

						&:hover,
						&:focus {
							background-color: $color_menu_background_hover;
						}

						svg {
							transition: all .25s;
							@extend %center;
							fill: $color_menu;
							width: 1.125rem;
							height: 1.125rem;
						}

						&:hover svg,
						&:focus svg {
							fill: $color_menu_hover;
						}
					}

					ul {
						clear: both;
						display: none;

						@media (min-width: $breakpoint_to_desktop_nav) {
							position: absolute;
							top: 100%;
							background-color: $color_menu_background;
							border-right: 1px solid $color_menu_borders;
							border-left: 1px solid $color_menu_borders;
						}

						li {
							display: block;

							@media (min-width: $breakpoint_to_desktop_nav) {
								&:first-of-type {
									border-top: 1px solid $color_menu_borders;
								}
							}

							a {
								padding-left: 2.6875rem;
								font-weight: 400;

								@media (min-width: $breakpoint_to_desktop_nav) {
									height: 3rem;
									padding: 0 1.25rem;
									line-height: 3rem;
									border-bottom: 1px solid $color_menu_borders;
								}

								&:hover,
								&:focus {
									color: $color_menu_hover;
									background-color: $color_menu_background_hover;
								}
							}

							ul li a {
								padding-left: 4rem;

								@media (min-width: $breakpoint_to_desktop_nav) {
									padding: 0 1.25rem;
								}
							}
						}
					}
				}

				a {
					display: block;
					height: 3rem;
					padding-left: 1.375rem;
					color: $color_menu;
					font-weight: 700;
					line-height: 3rem;
					text-decoration: none;
					text-transform: uppercase;
					border-bottom: 1px solid $color_menu_borders;

					@media (min-width: $breakpoint_to_desktop_nav) {
						padding: 0 1.75rem;
						white-space: nowrap;
						border-bottom: 0;
					}

					&:hover,
					&:focus {
						color: $color_menu_hover;
						background-color: $color_menu_background_hover;
					}
				}

				&.has-children.on > a.toggle svg {
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}
		}

		& > ul > li:last-of-type a {
			border-bottom: 0;
		}
	}
}

.page-banner {

}

.heading-nav-container {
	flex: 1 1 100%;

	.heading_nav {
		margin-bottom: 3rem;

		h1 {
			@include flexbox();
			@include flex-direction(column);
			@include justify-content(space-between);
			@include align-items(center);
			margin: 0;
			margin-bottom: 1rem;

			@media (min-width: 34em) {
				@include flex-direction(row);
			}

			@media (min-width: 40em) {
				@include flex-direction(column);
			}

			@media (min-width: 54em) {
				@include flex-direction(row);
			}

			span {
				color: $color_comet;
				font-weight: 400;
				font-size: 2rem;
				letter-spacing: .0109375em;
			}

			a {
				padding: 0 2rem;
				color: $color_white;
				font-weight: 600;
				font-size: .875rem;
				line-height: 2.375rem;
				background-color: $color_carrot_orange;
				border-radius: 1000rem;
			}

			.select {
				width: auto;
				margin: 0;

				&:after {
					content: url('../../svgs/chevron-down-small-comet.svg');
				}

				select {
					color: $color_comet;
					font-size: .875rem;
					border: 1px solid $color_comet;
					border-radius: 1000rem;
				}
			}
		}
	}
}