#main-content {
	flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 100%;
	min-height: calc(100vh - 4rem);
	margin-top: 4rem;
	margin-left: 0;

	@media (min-width: $breakpoint_to_full_sidebar) {
		margin-left: 15.625rem;
		width: calc(100% - 15.625rem);
		max-width: calc(100% - 15.625rem);
	}

	@media only print {
		width: 100%;
		max-width: 100%;
		margin-top: 0;
		margin-left: 0;
	}

	h1.spacing {
		max-width: 73rem;
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 1.5rem;

	}

	h1.full-spacing {
		margin-right: auto;
		margin-left: auto;
		padding: 1rem 1.5rem;
	}

	.site_nav {
		position: fixed;
		top: 0;
		right: 0;
		left: 4rem;
		display: flex;
		justify-content: space-between;
		background-color: $color_white;
		border-bottom: 1px solid $color_alto;
		border-left: 1px solid $color_alto;
		box-shadow: none;
		z-index: 9000;

		@media (min-width: $breakpoint_to_full_sidebar) {
			left: 15.625rem;
		}

		@media only print {
			display: none;
		}

		> div {
			display: flex;
			justify-content: space-between;
			width: 100%;

			> div {
				display: flex;

				> div {
					display: flex;
					align-items: center;
					border-right: 1px solid $color_alto;

					@media (min-width: $breakpoint_to_full_sidebar) {
						position: relative;
						border-right: 0;
					}

					a {
						display: flex;
						align-items: center;
						padding: 0 1rem;
						height: 4.25rem;
						line-height: 4.25rem;

						svg {
							display: block;

							circle {
								display: none;
							}

							path {
								transition: .25s all;
							}
						}

						&:hover,
						&:focus,
						&.active {
							svg path {
								fill: $color_carrot_orange;
							}
						}

						&.new svg circle {
							display: inline;
						}
					}

					a + div {
						position: absolute;
						top: calc(100% + 1px);
						right: .75rem;
						left: .75rem;
						display: none;
						max-height: calc(100vh - 4.75rem);
						background-color: $color_white;
						border: 1px solid $color_alto;
						border-top: 0;
						border-radius: 0 0 8px 8px;
						box-shadow: 0 .3125rem .375rem rgba($color_black, .14);
						overflow-y: auto;
						overflow-x: hidden;

						&#account-dropdown, &#member-quick-search {
							@media (max-width: $breakpoint_to_full_sidebar) {
								left: calc(-4rem + 1rem);
								right: 1rem;
							}
						}

						&.search_wrapper {
							left: 0;

							h3 {
								margin: 1rem 1.5rem
							}

							form {
								display: block;
								margin: 1rem 1.5rem 0;

								@media (min-width: 45em) {
									display: flex;
									flex-wrap: wrap;
									justify-content: space-between;
								}

								.form-row {
									@media (min-width: 45em) {
										flex: 1 1 48%;
										max-width: 48%;
									}

									&.checkbox {
										@media (min-width: 45em) {
											flex: 1 1 100%;
											max-width: 100%;
											margin-top: -1rem;
											margin-bottom: 1rem;

											label {
												justify-content: flex-end;

												.field-label {
													flex: 0 1 auto;
													min-width: 0;
													width: auto;
												}
											}
										}

										.field-label {
											color: $color_secondary_purple;
											font-size: .875rem;
										}
									}

									&.buttons {
										position: relative;
										left: calc(-1.5rem - 1px);
										width: calc(100% + 3rem + 2px);
										margin: 0;

										@media (min-width: 45em) {
											flex: 1 1 100%;
											min-width: calc(100% + 3rem + 2px);
											max-width: calc(100% + 3rem + 2px);
										}

										input[type=submit],
										button {
											width: 100%;
											margin: 0;
											text-transform: none;
											border-radius: 0 0 8px 8px;
										}
									}
								}
							}
						}

						@media (min-width: $breakpoint_to_full_sidebar) {
							right: auto;
							left: .25rem;
							width: 15.625rem;

							&.search_wrapper {
								left: 0;
								min-width: 15.625rem;
								width: 50vw;
								max-width: 40rem;
							}
						}

						form {
							display: flex;
							margin: 0;
							padding: 0;

							input[type=search] {
								flex: 1 1 auto;
								background-color: transparent;
								border: 0;
							}

							button {
								flex: 0 1 auto;
								display: flex;
								align-items: center;
								min-width: 0;
								padding: .625rem 1rem;
								background-color: transparent;
								border: 0;
							}
						}

						header {
							display: flex;
							justify-content: center;
							align-items: center;
							color: $color_scorpion;
							letter-spacing: .058125em;
							line-height: 2.5;
							text-align: center;
							border-bottom: 1px solid $color_alto;

							.unread {
								display: flex;
								justify-content: center;
								align-items: center;
								width: 1.125rem;
								height: 1.125rem;
								color: $color_white;
								margin-left: .75rem;
								font-weight: 600;
								font-size: .75rem;
								background-color: $color_bittersweet;
								border-radius: 50%;
							}
						}

						.messages {
							list-style: none;
							margin: 0;
							padding: 0;

							li {
								display: flex;
								align-items: center;
								padding: .75rem .5rem;
								border-bottom: 1px solid $color_alto;
								cursor: pointer;

									&:hover {
										background-color: $color_alabaster2;
									}


								.icon {
									padding-right: .5rem;

									svg {
										display: block;
										width: 1.8125rem;
										height: 1.8125rem;
									}
								}

								.info {
									span {
										display: block;

										&:nth-of-type(1) {
											color: $color_boulder;
											font-size: .75rem;
										}

										&:nth-of-type(2) {
											color: #bbb;
											font-size: .625rem;
										}
									}
								}
							}
						}

						footer {
							text-align: center;

							a {
								display: inline-block;
								height: auto;
								line-height: 2.5;
								text-decoration: none;
							}
						}
					}

					> .message_center {
						header.no_messages {
							text-align: center;

							+ .no_messages {
								display: flex;
								flex-direction: column;
								justify-content: center;
								align-items: center;
								height: 100%;
								padding: 2.5rem 1.5rem 1rem 1.5rem;
								color: $color_dove_gray;
								font-size: .875rem;
								border-bottom: 1px solid $color_alto;

								p {
									line-height: 1.5;
									text-align: center;
									font-weight: 500;
								}
							}
						}

						header.messages {
							justify-content: space-between;

							> a {
								height: auto;
								padding: 1rem;
								line-height: 1;

								svg path {
									fill: $color_gray;
									transition: .25s all;
								}

								&:hover,
								&:focus {
									svg path {
										fill: $color_carrot_orange;
									}
								}
							}
						}
					}
				}

				&:last-of-type div a + div {
					@media (min-width: $breakpoint_to_full_sidebar) {
						left: auto;
						right: .25rem;
						width: 15.625rem;
					}
				}
			}

			> div:last-of-type {
				> div > a {
					display: flex;
					align-items: center;
					padding: 0;
					color: $color_dove_gray;
					text-decoration: none;

					div {
						display: none;
						font-weight: 400;

						@media (min-width: 36em) {
							display: block;
						}

						@media (min-width: 40em) {
							display: none;
						}

						@media (min-width: 44em) {
							display: block;
						}

						span {
							color: $color_port_gore;
							font-weight: 600;
						}
					}

					svg {
						&:nth-of-type(1) {
							width: 1.5rem;
							height: 1.5rem;
							margin: 0 1rem;

							@media (min-width: 36em) {
								display: none;
							}

							@media (min-width: 40em) {
								display: block;
							}

							@media (min-width: 44em) {
								display: none;
							}
						}

						&:nth-of-type(2) {
							display: none;
							width: .75rem;
							height: .75rem;
							margin-right: 1rem;
							margin-left: 1rem;

							@media (min-width: 36em) {
								display: block;
							}

							@media (min-width: 40em) {
								display: none;
							}

							@media (min-width: 44em) {
								display: block;
							}
						}
					}
				}

				.messages li a {
					justify-content: center;
					width: 100%;
					height: 2rem;
					line-height: 2rem;
					white-space: nowrap;
				}
			}

			.account_links li {
				margin-bottom: 0;
				padding: .75rem .25rem;

				&.header {
					justify-content: center;
					color: #989898;
					font-weight: 700;
					font-size: .875rem;
					text-align: center;
					text-transform: uppercase;
					background-color: #fbfbfb;
				}

				a {
					display: flex;
					justify-content: flex-start !important;
					align-items: center;
					padding: 0 .75rem !important;
					color: $color_comet;
					font-weight: 400;

					.svg,
					svg {
						flex: 0 0 auto;
						width: 1.25rem;
						height: 1.75rem;
						margin-right: .75rem;

						svg {
							width: 100%;
							height: 100%;

							path {
								fill: $color_comet;
							}
						}
					}

					.label {
						margin-right: .75rem;
					}

					.check-circle {
						margin-right: 0;
						margin-left: auto;

						svg path {
							fill: $color_primary_green;
						}
					}

					&:hover,
					&:focus {
						color: $color_carrot_orange;

						span:not(.check-circle) svg path {
							fill: $color_carrot_orange;
						}
					}
				}
			}
		}
	}

	> section {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		position: relative;
		background-color: $color_alabaster;

		@media only print {
			background-color: transparent;
		}

		.full-padding {
			padding-left: 5rem;
			padding-right: 5rem;
		}

		.user-impersonation {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 1rem;
			color: #a16131;
			font-size: .75rem;
			text-align: center;
			background-color: #fef8ef;
			border-bottom: 1px solid #f9e5c7;

			.svg {
				flex: 0 0 1.4375rem;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: 1.4375rem;
				height: 1.4375rem;
				margin-right: 1rem;
				border: 1px solid currentColor;
				border-radius: 50%;

				svg {
					width: 50%;
					height: 50%;

					path {
						fill: #a16131;
					}
				}
			}

			.text {

			}
		}

		> .page_banner, > .ee-content .page_banner {
			position: relative;
			min-height: 18.75rem;
			height: 18.75rem;
			background: transparent center / cover no-repeat scroll;
			z-index: 1;

			@media only print {
				min-height: 0;
				height: auto;
				background: none !important;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: $color_east_bay;
				z-index: 2;
				opacity: .75;

				@media only print {
					display: none;
				}
			}

			h1 {
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				margin: 0;
				padding: 0 1.5rem;
				color: $color_white;
				text-align: center;
				z-index: 3;
				transform: translateY(-50%);

				@media only print {
					position: relative;
					top: auto;
					right: auto;
					left: auto;
					margin: 1rem 0 0 0;
					color: $color_comet;
					font-size: 2rem;
					line-height: 1.2;
					transform: translateY(0);
				}
			}
		}

		.ee-content {
			.add-to-brochure, .remove-from-brochure {

				position: absolute;
				font-size: 1.5rem;
				line-height: 1.5rem;
				height: 50px;
				width: 50px;
				min-width: 0;
				top: 0;
				right: 0;
				padding: 0;
				margin: 1.5rem;
				border-radius: 25px;
				box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
				outline: none;
				transition: all .25s;

				span.text {
					display: none;
					font-size: 0.85rem;
					line-height: 0.85rem;
					opacity: 0;
					text-align: right;

					position: absolute;
					top: 20px;
					right: 14px;
					min-width: 182px;
				}

				&:hover,
				&:focus {
					width: 182px;

					span.plus {
						position: absolute;
						left: 15px;
						top: 12px;
					}

					span.text {
						display: inline-block;
						opacity: 1;
					}
				}
			}

			.remove-from-brochure {
				background-color: $color_primary_green;

				.check, .remove {
					svg {
						width: 1rem;
						height: 1rem;
					}
				}
				.remove {
					display: none;
				}

				&:hover,
				&:focus {
					width: 250px;
					background-color: $color_monza;

					span.text {
						min-width: 202px;
						text-align: right;
						right: 14px;
					}

					.remove {
						display: initial;
						position: absolute;
						left: 13px;
						top: 13px;
						svg {
							path {
								fill: white;
							}
						}
					}

					.check {
						display: none;
					}
				}
			}


			.file-type {
				.brochure-builder {
					.add-to-brochure, .remove-from-brochure {

						margin: 0;
						height: 30px;
						width: 30px;
						left: 6px;
						top: -11px;
						font-size: 1.0rem;
						line-height: 1.0rem;

						&:hover,
							&:focus {
								width: 170px;
								height: 40px;
								left:0;
								top:0;

								span.plus {
									position: absolute;
									top: 10px;
									left: initial;
									right: 10px;
								}

								span.text {
									display: inline-block;
									opacity: 1;
									top: 13px;
									left: 16px;
									text-align: left;
								}
							}
					}

					.remove-from-brochure {
						background-color: $color_primary_green;

						.check, .remove {
							padding-left: 3px;
							padding-top: 2px;

							svg {
								width: 0.8rem;
								height: 0.8rem;

								path {
									fill: white;
								}
							}
						}
						.remove {
							display: none;
						}

						&:hover,
						&:focus {
							width: 250px;
							background-color: $color_monza;

							span.text {
								text-align: left;
								left: 37px;
							}

							.remove {
								display: initial;
								position: absolute;
								left: 16px;
								top: 11px;
								svg {
									path {
										fill: white;
									}
								}
							}

							.check {
								display: none;
							}
						}
					}
				}

				&:hover,
					&:focus {
						.brochure-builder {
							.add-to-brochure, .remove-from-brochure {
								width: 170px;
								height: 40px;
								left:0;
								top:0;

								span.plus {
									position: absolute;
									top: 10px;
									left: initial;
									right: 10px;
								}

								span.text {
									display: inline-block;
									opacity: 1;
									top: 13px;
									left: 16px;
									text-align: left;
								}
							}

							.remove-from-brochure {
								width: 250px;
								background-color: $color_monza;

								span.text {
									text-align: left;
									left: 37px;
								}

								.remove {
									display: initial;
									position: absolute;
									left: 16px;
									top: 11px;
									svg {
										path {
											fill: white;
										}
									}
								}

								.check {
									display: none;
								}
							}
						}
				}
			}
		}

		> [ng-view] {
			flex: 0 1 auto;
			width: 100%;
			//max-width: 80rem;
			margin: 0 auto;
			//padding: 1.5rem;

			.spacing {
				max-width: 80rem;
				margin-right: auto;
				margin-left: auto;
				padding: 1rem 1.5rem;
			}

			.heading_nav {
				margin-bottom: 3rem;

				h1 {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					margin: 0;
					margin-bottom: 1rem;

					@media (min-width: 34em) {
						flex-direction: row;
					}

					@media (min-width: 40em) {
						flex-direction: column;
					}

					@media (min-width: 54em) {
						flex-direction: row;
					}

					span {
						color: $color_comet;
						font-weight: 400;
						font-size: 2rem;
						letter-spacing: .0109375em;
					}

					a {
						padding: 0 2rem;
						color: $color_white;
						font-weight: 600;
						font-size: .875rem;
						line-height: 2.375rem;
						background-color: $color_carrot_orange;
						border-radius: 1000rem;
					}

					.select {
						width: auto;
						margin: 0;

						&:after {
							content: url('../../svgs/chevron-down-small-comet.svg');
						}

						select {
							color: $color_comet;
							font-size: .875rem;
							border: 1px solid $color_comet;
							border-radius: 1000rem;
						}
					}
				}

			}
		}
	}

	.notifications {
		svg circle {
			fill-opacity: 0;
		}

		&.new svg circle {
			fill-opacity: 1;
		}
	}

	.notifications_dd_list {
		span.unread {
			display: none !important;
		}

		&.new span.unread {
			display: flex !important;
		}
	}
}

.site_wrapper {
	width: 100%;
}

.onboarding {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	margin: 0 auto;
	padding: 0 1.5rem;
	text-align: center;
	background-color: white;

	.onboard_wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 57rem;
		min-height: calc(100vh - 9.0625rem);
		margin: 0 auto;
		padding: 3rem 0;

		@media (min-width: 40em) {
			min-height: calc(100vh - 4.5625rem);
		}

		.wrapper {
			width: 100%;
		}
	}

	.welcome {
		font-family: "Open Sans", serif;
		//background: transparent url(/assets/svgs/double-wave.svg) center 100% / 100% auto no-repeat scroll;
		h1 {
			font-weight:400;
		}

		.step_links {
			$step_links_margin_columns: 3.375rem;
			margin-top: 5rem;
			margin-right: -#{$step_links_margin_columns / 2};
			margin-left: -#{$step_links_margin_columns / 2};

			@media (min-width: 36em) {
				display: flex;
				flex-wrap: wrap;
			}

			a {
				flex: 1 1 auto;
				display: block;
				width: calc(100% - #{$step_links_margin_columns});
				margin-right: #{$step_links_margin_columns / 2};
				margin-bottom: 2rem;
				margin-left: #{$step_links_margin_columns / 2};
				padding: 2rem 1.5rem;
				color: $color_dove_gray;
				background-color: $color_white;
				border: 1px solid $color_swiss_coffee;
				border-radius: 8px;

				@media (min-width: 36em) {
					width: calc((100% - #{$step_links_margin_columns * 2}) / 2);
					max-width: calc((100% - #{$step_links_margin_columns * 2}) / 2);
				}

				svg {
					path,
					polygon,
					rect {
						transition: all .25s;
					}
				}

				header {
					padding-top: 1rem;
					font-weight: 600;
					font-size: 1.125rem;
					text-align: center;
				}

				&:hover,
				&:focus {
					color: $color_white;
					background-color: $color_waterloo;

					svg {
						path,
						polygon {
							fill: $color_white;
						}

						rect {
							fill: $color_waterloo;
						}
					}
				}
			}
		}
	}

	.split {
		width: 100%;
		max-width: 100%;
		padding: 3rem 0;

		@media (min-width: 60em) {
			display: flex;
			justify-content: center;
		}

		> div {

			border: #cdcdcd 1px solid;
			border-radius: 10px;
			padding: 0.5rem;

			@media (min-width: 60em) {
				flex: 1 1 auto;
				display: flex;
				flex-direction: column;
				// justify-content: space-between;
				justify-content: center;
				width: 50%;
				max-width: 50%;
			}

			&:nth-of-type(1) {
				margin-bottom: 4rem;

				@media (min-width: 60em) {
					margin-right: 4rem;
					margin-bottom: 0;
				}
			}

			figure img {
				width: auto;
			}

			p {
				max-width: 100%;
			}
		}
	}

	img {
		border: #cdcdcd 1px solid;
		border-radius: 10px;
		padding: 0;

		width: auto;
		max-width: 100%;
		// height: auto;
		max-height: 20rem;

		&.no-border {
			border: none;
		}

	}

	.split {
		img {
			border: none;

		}
	}

	p + img {
		margin-top: 3rem;
	}

	img + h2 {
		margin-top: 3rem;
	}

	h2 {
		color: inherit;
		font-size: 1.25rem;
	}

	.button {
		margin-top: 4rem;
		padding-top: .6875rem;
		padding-bottom: .6875rem;

		&.view_dashboard {
			padding-right: 3rem;
			padding-left: 3rem;
			border-radius: 1000rem;
		}

		&.next,
		&.finish {
			min-width: 10rem;
			letter-spacing: .1375em;
			background-color: $color_mantis;
			border-color: $color_mantis;
			border-radius: 4px;

			&:hover,
			&:focus {
				background-color: darken($color_mantis, 12);
				border-color: darken($color_mantis, 12);
			}
		}

		&.finish {
			margin: 2rem 0;
		}
	}

	.progress_nav {
		border: 1px solid #dbdbdb;
	}
}

[ng-view] {
	.onboarding {
		display: flex;
	}
}

#save-on-prescriptions {
	.content {
		margin: 3rem auto 3rem 1.5rem;



		.prescription_buttons {
			display: flex;
			justify-content: center;
			padding: 2rem;

			@media(max-width: 55rem) {
				display: block;
				width: 100%;
			}

			.button {
				display: flex;
				min-height: 230px;
				background: none !important;
				margin-top: 0;
				@media(max-width: 55rem) {
					width: 100%;
				}


				.number {
					position: absolute;
					z-index: 200;
					width: 3rem !important;
				}

				a {
					margin: .75rem;
					padding: 1rem 1.5rem;
					text-align: center;
					background-color: #fff;
					border: 1px solid $color_comet;
					border-radius: 8px;
					min-width: 230px;
					max-width: 350px;

					svg {
						height: 6rem;
						width: 6rem;
						fill: none;
					}

				}

				p {
					text-align: center;
					color: $color_dove_gray;
					font-weight: 600;
					text-transform: none;
				}
			}

		}

		.prescription_savings_tips {
			margin-left: 2em;

			ul {
				padding-right: 1rem;
			}

			li {
				list-style: none;
			}
		}

		.quick_links {
			margin-bottom: 5rem;
			text-align: center;

			.quick_link_buttons {
				display: flex;
				justify-content: center;

				@media(max-width: 60rem) {
					display: block;
					width: 100%;
				}

				#icon_envelope_fix {
					&:hover svg path {
						fill: none !important;
						stroke: $color_carrot_orange !important;
					}
				}

				.button {
					&:hover svg path {
						fill: $color_carrot_orange;
					}

					background: none;
					display: flex;
					min-height: 230px;
					margin: 0;
					text-transform: none;


					a {
						margin: .75rem;
						padding: 1rem 1.5rem;
						text-align: center;
						background-color: #fff;
						border: 1px solid $color_comet;
						border-radius: 8px;
						min-width: 250px;
						max-width: 400px;

						svg {
							height: 5rem;
							width: 5rem;
							margin-top: 2em;
						}
					}

					p {
						text-align: center;
						color: $color_dove_gray;
						font-weight: 600;
					}
				}

			}

		}

		.video_gallery {

			header {
				text-align: center;
			}


			$video_margin_columns: 3.25rem;

			.large_screen_video {
				.glide {
					max-width: 960px;
					margin: auto;

					.glide__slide {
						// Individual slide styles
						max-width: 300px;
						margin: auto;

						figure {
							margin: 0;
							width: 100%;
							height: auto;
							position: relative;

							&:hover {
								.play svg {
									fill: $color_carrot_orange;
								}
							}

							img {
								width: 100%;
								height: auto;
							}

							.play {
								position: absolute;
								top: 50%;
								left: 50%;
								width: 20%;
								transform: translate(-50%, -50%);

								svg {
									fill: initial;
									transition: fill 0.3s ease;
								}
							}
						}
					}

					.glide__arrows {
						display: flex;
						justify-content: center;
						width: 100%;
						transform: translateY(-50%);
						margin-top: 2rem;

						.glide__arrow {
							margin: 0 10px;

							svg {
								width: 2rem;
								height: 1rem;

								&:hover path {
									fill: $color_secondary_purple;
								}
							}
						}
					}
				}
			}

		}
	}

	.faqs {
		margin-right: 2rem;

		header{
			text-align: center;
		}

		.glossarize {
			margin: 0 !important;
		}
	}

}





